<template>
  <div class="region-informer">
    <div class="region-informer__navbar">
      <div class="region-informer__navbar-top">
        <div @click="$router.push({ path: '/data-region', query: {ratingRegion: true} })" class="region-informer__navbar-top-back">
          <img src="../../../assets/svg/arrows/back.svg" alt="">
          <p>{{ $t('back') }}</p>
        </div>
        <h1 v-html="getUser?.role === 'Moderator' || getUser?.role === 'Admin' ? $t('fill-in-indicators') : $t('viewing-indicators')"></h1>
        <div class="region-informer__navbar-top-items" v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'">
          <router-link
              to="/region-indicators/filling-indicators"
              class="incident-types"
          >{{ $t('filling-in-the-indicators') }}</router-link>
          <router-link
              to="/region-indicators/view-indicators"
              class="incident-types"
          >{{ $t('viewing-indicators') }}</router-link>
        </div>
      </div>
      <ui-menu v-model="visibleSettings" v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'">
        <template #activator>
          <img alt="" :src="require(`@/assets/svg/icon-settings-${settingsColor}.svg`)" class="nav-bar__settings" />
        </template>
        <template #body>
          <div class="sort">
            <router-link
                to="/region-indicators/filling-indicators"
                class="incident-types"
                @click.native="visibleSettings = !visibleSettings"
            >{{ $t('filling-in-the-indicators') }}</router-link>
            <router-link
                to="/region-indicators/view-indicators"
                class="incident-types"
                @click.native="visibleSettings = !visibleSettings"
            >{{ $t('viewing-indicators') }}</router-link>
          </div>
        </template>
      </ui-menu>
    </div>
    <router-view />
  </div>
</template>

<script>
import UiMenu from "@/components/ui/UiMenu.vue";
import {mapGetters} from "vuex";
export default {
  name: "Index",
  components: {UiMenu},

  data() {
    return {
      visibleSettings: false,
    }
  },

  computed: {
    ...mapGetters(['getUser']),
    settingsColor () {
      return this.visibleSettings ? 'red' : 'black'
    }
  },

}
</script>

<style lang="scss" scoped>
.region-informer {
  display: flex;
  gap: 30px;
  min-height: 100vh;
  height: 100%;
  padding: 150px 50px 150px 50px;

  @media(max-width: 1200px) {
    flex-direction: column;
    padding: 120px 20px 50px 20px;
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    max-width: 320px;
    width: 100%;

    @media (max-width: 1200px) {
      flex-direction: row;
      max-width: 100%;
    }

    &-top {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        color: #1B1A1F;
        margin-bottom: 30px;
      }

      &-items {
        display: flex;
        flex-direction: column;

        @media (max-width: 1200px) {
          display: none;
        }

        .incident-types {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #1B1A1F;
          margin-bottom: 20px;
          white-space: nowrap;
        }

        .accordion-incident-types {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 13px;
          color: #343432;
          text-decoration: none;
        }

        .router-link-active {
          color: #CE2121
        }
      }

      &-back {
        display: flex;
        gap: 8px;
        cursor: pointer;

        @media (max-width: 1000px) {
          display: none;
        }

        p {
          font-weight: 400;
          font-size: 20px;
          line-height: 22px;
          color: #1B1A1F;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    &-bottom {
      color: #9A9A9A;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;

      @media (max-width: 1200px) {
        position: absolute;
        top: 30px;
      }
    }
  }
}
.nav-bar__settings {
  display: none;

  @media (max-width: 1200px) {
    display: inline-block;
  }
}
::v-deep .accordion {
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    margin-bottom: 0;
  }
  &__header {
    padding: 0 !important;
  }

  &__body {
    padding-left: 15px;

    &-inner {
      gap: 16px;
      display: flex;
      flex-direction: column;

      &-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 10px;

        &.router-link-active {
          &::before {
            background-color: #CE2121 !important;
          }
        }

        &::before {
          content: '';
          width: 5px;
          height: 5px;
          background-color: black;
          border-radius: 50%;
        }
      }
    }
  }
}
::v-deep .router-link-active {
  color: #CE2121
}
</style>
